<template>
	<div id="app">
		<!-- <index></index> -->
		<router-view />
	</div>
</template>

<script>
	// import index from './view/index.vue'
	export default {
		// name: 'App',
		// components: {
		// 	index
		// }
	}
</script>

<style>
	
	html,
	body {
		/* height: 100%; */
		margin: 0;
		padding: 0;
		width: 100%;
	}

	html {
		font-family: Arial, Helvetica, sans-serif, "宋体"
	}

	.relative {
		position: relative;
	}

	.absolute {
		position: absolute;
	}

	.d-no {
		display: none;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		height: 100%;
	}
</style>
