/**
    axios的配置文件
    1、设置post数据格式为表单格式
    2、设置基路径
    3、序列化字符串
*/
import axios from 'axios';
// import qs from 'qs';
//配置
//配置前后端数据交互的请求头：
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 设置后台的访问地址
axios.defaults.baseURL = 'https://siteapi.renhegroup.net/site-api';

// 请求拦截
// axios.interceptors.request.use((config) => {
// 	// if (config.method === 'post') {
// 	//     config.data = qs.stringify(config.data,{arrayFormat: 'repeat' });
// 	// }
// 	// return config;
// }, (error) => {
// 	// return Promise.reject(error);
// });

// 响应拦截
axios.interceptors.response.use(
	response => {
		// 对响应数据做处理
		// console.log(response)
		return response;
	},
	error => {
		if (error.response) {
			switch (error.response.status) {
				case 401:
					// router.push({
					// 	path: './login'
					// })
			}
		}
		return Promise.reject(error);
	}
)

export default axios;


//在vue主组件中引入即可
// import axios from '@/http/axios'
