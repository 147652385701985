<template>
  <div class="box">
    <!-- 弹窗时的遮罩 -->
    <div
      class="mask"
      style="background-color: rgba(102, 102, 102, 0.6)"
      v-show="showShareSuccess || showReceive || showLead || showCode"
    ></div>
    <!-- <van-loading size="24px"  class="canvas_loading" vertical  >绘制中...</van-loading> -->
    <van-loading
      type="spinner"
      size="30px"
      class="canvas_loading"
      color="#0484FB"
      v-if="showLoading"
    />
    <!-- 分享成功 -->
    <!-- <transition name="slide"> -->
    <div
      class="container mask share_success_box"
      v-if="showShareSuccess"
      @click="close"
      data-close="true"
      @touchmove.prevent
    >
      <div class="canvas_box" @click="close" data-close="true" v-if="ShareImg">
        <img :src="ShareImg" />
      </div>
      <div class="share_success relative" ref="abcdefg">
        <div class="share_success_top_box absolute">
          <img
            class="share_success_top"
            src="../assets/share_success/top.png"
          />
        </div>
        <div class="avatar_box">
          <img
            class="share_success_avatar absolute"
            src="../assets/share_success/avatar.png"
          />
        </div>
        <div class="other">
          <div class="name">
            领券享0元建站，限100个名额，先领先得，小程序一站领取
          </div>
          <div id="qrcode" ref="qrcode" class="aaa"></div>
        </div>
        <!-- 靠近底部的直接用bottom -->
        <div class="share_success_bottom">
          <div class="savesave">长按保存，在微信分享给好友或朋友圈</div>
          <div>拇指哥，一站式全网展示，让你业务不受限</div>
          <div>www.muzhie.com.cn</div>
        </div>
      </div>
    </div>
    <!-- </transition> -->

    <!-- 领取成功 -->
    <transition name="slide">
      <div
        class="container mask receive_success_box"
        v-if="showReceive"
        @click="close"
        data-close="true"
        @touchmove.prevent
      >
        <div class="receive_success relative">
          <img
            class="receive_success_top"
            src="../assets/receive/receive.png"
          />
          <img
            class="receive_success_close"
            src="../assets/receive/close.png"
            @click="close"
            data-close="true"
          />
          <!-- 显示输入框 -->
          <template v-if="!receiveSuccess">
            <!-- 输入框 -->
            <div class="receive_success_input">
              <div>
                <input
                  class="receive_success_input1"
                  type="number"
                  placeholder="请输入手机号"
                  @input="phoneInput"
                  :value="phoneNum"
                />
              </div>
              <!-- 第二行有验证码按钮 -->
              <div class="receive_success_line2">
                <input
                  class="receive_success_input2"
                  type="text"
                  placeholder="输入验证码"
                  @input="codeInput"
                  :value="codeNum"
                />
                <div :class="receive_success_line2_btn">
                  <div
                    v-if="!receive_success_line2_btn.active"
                    @click="gainCode"
                  >
                    获取验证码
                  </div>
                  <div v-else>剩余{{ code }}秒</div>
                </div>
              </div>
              <!-- <div>
                <input
                  class="receive_success_input1"
                  type="password"
                  placeholder="设置密码"
                  @input="pwdInput"
                  :value="pwdNum"
                />
              </div> -->
            </div>
            <!-- 领取按钮 -->
            <div class="receive_success_btn" @click="receiveNow">立即领取</div>
          </template>
          <!-- 点击了领取之后 显示领取成功 -->
          <template v-else>
            <div class="receive_success_tip">领取成功</div>
            <div class="receive_success_nav">
              即将跳转至拇指哥官网<span @click="navNow">手动跳转</span>
            </div>
          </template>
        </div>
      </div>
    </transition>

    <!-- 引导分享 -->
    <div class="lead" v-if="showLead" @click="ClosLead" @touchmove.prevent>
      <img src="../assets/receive/share_lead.png" class="share_lead" />
    </div>

    <!-- 分享的二维码 -->
    <div
      class="share_code"
      v-if="showCode"
      @click="close"
      data-close="true"
      @touchmove.prevent
    >
      <div></div>
    </div>

    <!-- 首页 -->
    <div class="container">
      <share-sheet
        v-model="showShare"
        title="立即分享给好友"
        :options="shareOptions"
        @select="onSelect"
      />
      <!-- 顶部 -->
      <div class="top relative">
        <!-- 背景图 -->
        <img class="top_bg absolute" src="../assets/index/top_bg.png" />
        <!-- 左上角树叶 -->
        <img class="top_left absolute" src="../assets/index/top_left.png" />
        <!-- 拇 -->
        <img class="mu absolute wow bounce" src="../assets/index/mu.png" />
        <!-- 指 -->
        <img class="zhi absolute wow bounce" src="../assets/index/zhi.png" />
        <!-- 哥 -->
        <img class="ge absolute wow bounce" src="../assets/index/ge.png" />
        <!-- 来啦 -->
        <img
          class="laila absolute wow fadeInRight"
          data-wow-delay="1s"
          src="../assets/index/laila.png"
        />
        <!-- 人 -->
        <img
          class="top_people absolute wow fadeInRight"
          data-wow-delay="1s"
          src="../assets/index/top_people.png"
        />
        <!-- 右边树叶 -->
        <img class="top_right absolute" src="../assets/index/top_right.png" />
        <!-- 右边陆地 -->
        <img
          class="top_bottom absolute"
          src="../assets/index/top_bottom.png"
          crossOrigin="Anonymous"
        />
        <!-- 左边树叶 -->
        <img
          class="top_bottom_left absolute"
          src="../assets/index/top_bottom_left.png"
        />
        <!-- 限额 -->
        <img
          class="count_limit absolute wow fadeInUp"
          data-wow-delay="1.5s"
          :src="imgs ? imgs.titleImg : ''"
          crossOrigin="Anonymous"
        />
        <!-- 不用怕 -->
        <img
          class="no_fear absolute"
          data-wow-delay="2s"
          src="../assets/index/no_fear.png"
        />
        <!-- 一个图片好像实现不了先从上面掉下来之后再一直闪，改为两个图片，然后控制图片的显示隐藏 -->
        <img
          class="buyongpa absolute wow bounceInDown"
          ref="noFearWord1"
          data-wow-delay="3s"
          src="../assets/index/buyongpa.png"
        />
        <img
          class="buyongpa absolute wow pulse d-no"
          ref="noFearWord2"
          data-wow-iteration="infinite"
          src="../assets/index/buyongpa.png"
        />
        <img
          class="buxian absolute wow fadeInLeft"
          data-wow-delay="2s"
          src="../assets/index/buxian.png"
        />
        <!-- 底部礼物图片 -->
        <img
          class="top_bottom_gift gift absolute"
          src="../assets/index/right_gift.png"
        />
        <img
          class="top_bottom_left_gift gift absolute"
          src="../assets/index/left_gift.png"
        />
        <img
          class="top_bottom_cao absolute"
          src="../assets/index/top_bottom_cao.png"
        />
      </div>
      <!-- 中间的四个特点 -->
      <div>
        <!-- 多终端 -->
        <div class="advantage relative">
          <img
            class="absolute title zhongduan"
            src="../assets/index/zhongduan.png"
          />
          <div class="absolute content">
            <img class="box" src="../assets/index/01.png" />
            <img class="word" src="../assets/index/word01.png" />
          </div>
          <img
            class="advantage01_gift gift"
            src="../assets/index/left_gift.png"
          />
        </div>
        <!-- 更便捷 -->
        <div class="advantage relative">
          <img
            class="absolute title zhongduan"
            src="../assets/index/bianjie.png"
          />
          <div class="absolute content">
            <img class="box" src="../assets/index/02.png" />
            <img class="word" src="../assets/index/word02.png" />
          </div>
          <img
            class="advantage02_gift1 gift"
            src="../assets/index/right_gift.png"
          />
          <img
            class="advantage02_gift2 gift"
            src="../assets/index/left_gift.png"
          />
          <img
            class="advantage02_gift3 gift"
            src="../assets/index/left_gift.png"
          />
        </div>
        <!-- 易扩展 -->
        <div class="advantage relative">
          <img
            class="absolute title zhongduan"
            src="../assets/index/kuozhan.png"
          />
          <div class="absolute content">
            <img class="box" src="../assets/index/03.png" />
            <img class="word word2" src="../assets/index/word03.png" />
          </div>
          <img
            class="advantage03_gift1 gift"
            src="../assets/index/right_gift.png"
          />
          <img
            class="advantage03_gift2 gift"
            src="../assets/index/left_gift.png"
          />
        </div>
        <!-- 永升级 -->
        <div class="advantage relative">
          <img
            class="absolute title zhongduan"
            src="../assets/index/shengji.png"
          />
          <div class="absolute content">
            <img class="box" src="../assets/index/04.png" />
            <img class="word word2" src="../assets/index/word04.png" />
          </div>
          <img
            class="advantage04_gift1 gift"
            src="../assets/index/left_gift.png"
          />
          <img
            class="advantage04_gift2 gift"
            src="../assets/index/right_gift.png"
          />
        </div>
      </div>
      <!-- 限时零元秒杀 站位 -->
      <div class="seckill relative">
        <img class="seckill_gift1 gift" src="../assets/index/left_gift.png" />
        <img class="seckill_gift2 gift" src="../assets/index/left_gift.png" />
        <img class="hand" src="../assets/index/zhizhen.png" />
        <img class="xianshimiaosha absolute" :src="imgs ? imgs.saleImg : ''" />
        <img class="zhong absolute" src="../assets/index/zhong.png" />
      </div>
      <!-- 优惠券 -->
      <div class="coupon relative">
        <div class="coupon_top">
          <img
            ref="quan"
            class="coupon_top wow pulse"
            data-wow-iteration="infinite"
            data-wow-duration="3s"
            @click="recive"
            :src="imgs ? imgs.couponImg : ''"
          />
        </div>
        <img class="coupon_zhehoujia" src="../assets/index/zhehoujia.png" />
        <img class="coupon_zhehou" :src="imgs ? imgs.priceImg : ''" />
      </div>
      <!-- 分享 -->
      <div class="share relative">
        <img class="share_gift1 gift" src="../assets/index/left_gift.png" />
        <img class="share_gift2 gift" src="../assets/index/right_gift.png" />
        <img class="share_gift3 gift" src="../assets/index/left_gift.png" />
        <img class="share_gift4 gift" src="../assets/index/left_gift.png" />
        <img class="share_img" src="../assets/index/share.png" />
        <!-- 指尖分享 -->
        <div class="zhijian relative">
          <div class="zhijian_border"></div>
          <img class="zhijian_word" src="../assets/index/zhijian.png" />
          <img class="zhijian_shu" src="../assets/index/shu.png" />
        </div>
        <!-- 立即分享 -->
        <div class="share_now relative">
          <div class="zhijian_border"></div>
          <img class="share_content" src="../assets/index/yongjin.png" />
          <div class="share_btn wow bounceIn" @click="share"></div>
        </div>
        <img class="share_gift5 gift" src="../assets/index/left_gift.png" />
      </div>
      <!-- 底部联系 -->
      <div class="contact relative">
        <div></div>
        <img class="contact_gift1 gift" src="../assets/index/right_gift.png" />
        <!-- 咨询按钮 -->
        <div class="phone" @click="ppp">
          <img src="../assets/index/phone.png" />
        </div>
        <img class="contact_bottom" src="../assets/index/bottom.png" />
      </div>
    </div>
    <textarea id="input">这是幕后黑手</textarea>
  </div>
</template>

<style scoped>
#input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
}
.box {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.container {
  /* display: flex; */
  width: 375px;
  background-color: #fae7c3;
}

.top {
  width: 375px;
  height: 415px;
}

.top_bg {
  width: 375px;
  height: 281px;
  left: 0;
  top: 0;
  z-index: 3;
}

.top_left {
  width: 44px;
  height: 36px;
  left: 0;
  top: 0;
}

/* 拇指哥 */
.top_left,
.mu,
.zhi,
.ge,
.laile,
.top_people,
.count_limit {
  z-index: 9;
}

.mu {
  width: 74px;
  height: 76px;
  top: 57px;
  left: 25px;
}

.zhi {
  width: 72px;
  height: 74px;
  top: 79px;
  left: 98px;
}

.ge {
  width: 74px;
  height: 70px;
  top: 50px;
  left: 176px;
}

.laila {
  width: 51px;
  height: 25px;
  top: 116px;
  left: 175px;
  z-index: 66;
}

.top_people {
  width: 136px;
  height: 161px;
  top: 58px;
  left: 229px;
}

.top_right {
  width: 86px;
  height: 127px;
  left: 289px;
  top: 113px;
  z-index: 8;
}

.top_bottom {
  width: 200px;
  height: 91px;
  left: 176px;
  top: 191px;
  z-index: 7;
}

.top_bottom_left {
  width: 82px;
  height: 135px;
  left: 0;
  top: 162px;
  z-index: 7;
}

.count_limit {
  width: 196px;
  height: 45px;
  top: 160px;
  left: 43px;
}

/* 不用怕 */
.no_fear {
  width: 361px;
  height: 199px;
  top: 221px;
  left: 9px;
  z-index: 10;
}

.buyongpa {
  width: 158px;
  height: 59px;
  top: 264px;
  left: 169px;
  z-index: 10;
}

.buxian {
  width: 288px;
  height: 92px;
  top: 269px;
  left: 44px;
  z-index: 100;
}

.fenliuziti {
  font-size: 15px;
  letter-spacing: 1px;
  left: 60px;
  top: 269px;
  z-index: 11;
  color: #fff;
}

.jizhong {
  top: 289px;
}

.chengbengao {
  top: 310px;
}

.quanzhanshi {
  top: 344px;
  left: 44px;
}

.gift {
  width: 36px;
  height: 40px;
  position: absolute;
}

/* 底部礼物图片 */
.top_bottom_gift {
  left: 198px;
  top: 388px;
}

.top_bottom_left_gift {
  left: 47px;
  top: 423px;
}

.top_bottom_cao {
  width: 78px;
  height: 118px;
  left: 297px;
  top: 349px;
  z-index: 30;
}

/* 中间的四个特点 */
.advantage {
  height: 215px;
}

/* 特点的标题 */
.advantage .title {
  width: 187px;
  height: 55px;
  left: 95px;
  top: 20px;
  z-index: 2;
}

/* 特点的内容 */
.advantage .content {
  width: 345px;
  height: 124px;
  left: 15px;
  top: 81px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 特点的框 */
.advantage .content .box {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* 特点的字 */
.advantage .content .word {
  position: relative;
  top: 7px;
  width: 245px;
  height: 57px;
  z-index: 66;
}

.advantage .content .word2 {
  height: 36px;
}

/* 特点一里面的礼物图标 */
.advantage01_gift {
  left: 258px;
  top: 188px;
  z-index: 13;
}

/* 特点二里面的礼物图标 */
.advantage02_gift1 {
  left: 20px;
  top: 25px;
  z-index: 13;
}

.advantage02_gift2 {
  left: 132px;
  top: 53px;
  z-index: 1;
}

.advantage02_gift3 {
  left: 81px;
  top: 219px;
  z-index: 1;
}

/* 特点三里面的礼物图标 */
.advantage03_gift1 {
  left: 325px;
  top: 36px;
}

.advantage03_gift2 {
  left: 56px;
  top: 180px;
  z-index: 13;
}

/* 特点四里面的礼物图标 */
.advantage04_gift1 {
  left: 109px;
  top: 53px;
}

.advantage04_gift2 {
  left: 201px;
  top: 177px;
  z-index: 13;
}

/* 限时秒杀 */
.seckill {
  height: 145px;
}

.seckill_gift1 {
  top: 42px;
  left: -20px;
}

.seckill_gift2 {
  left: 318px;
  bottom: -25px;
}

.xianshimiaosha {
  width: 217px;
  height: 116px;
  top: 10px;
  left: 53px;
  /* background-image: linear-gradient(to top, #fae7c3, #fae7c3,#fae7c3, #fae7c3, transparent, transparent, transparent, transparent); */
}

.zhong {
  width: 128px;
  height: 136px;
  top: 10px;
  left: 225px;
}

/* 优惠券 */
.coupon {
}

.coupon_top {
  width: 375px;
  height: 113px;
  position: relative;
  left: -5px;
}

.coupon_top > img {
  width: 100%;
  height: 100%;
}

.coupon_zhehoujia {
  width: 339px;
  height: 107px;
  margin: 0 auto;
  margin-top: -25px;
}

.coupon_zhehou {
  width: 306px;
  height: 38px;
  position: absolute;
  bottom: 40px;
  left: 35px;
}

/* 分享 */

.share_gift1 {
  left: 38px;
  top: 14px;
  left: 20px;
}

.share_gift2 {
  left: 132px;
  top: 0;
}

.share_gift3 {
  right: 9px;
  top: 88px;
}

.share_gift4 {
  left: 174px;
  top: 170px;
}

.share_img {
  width: 273px;
  height: 102px;
  margin: 66px auto 0 auto;
}

/* 指尖分享 */
.zhijian {
  width: 333px;
  height: 42px;
  background: #9d56d7;
  margin: 24px auto 0;
  z-index: 9;
}

.zhijian_word {
  width: 192px;
  height: 20px;
  line-height: 42px;
  margin: 9px auto;
}

.zhijian_border {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -6px;
  top: -6px;
  border: 1px solid #fff;
}

.zhijian_shu {
  width: 302px;
  height: 29px;
  margin: 0 auto 0;
  position: relative;
  top: -10px;
  z-index: 999;
}

/* 立即分享 */
.share_now {
  width: 333px;
  height: 101px;
  background: #9d56d7;
  margin: 17px auto 0;
  z-index: 6;
}

.share_content {
  width: 279px;
  height: 37px;
  margin: 13px auto 0px;
}

.share_btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  vertical-align: bottom;
  position: relative;
  width: 144px;
  height: 24px;
  line-height: 24px;
  background-image: url(../assets/index/share_now_btn.png);
  background-size: 144px 24px;
  margin: 0 auto 0px;
}

.share_zhi {
  width: 15px;
  height: 11px;
  margin-left: 10px;
}

.share_now_word {
  color: #421d06;
  font-size: 6px;
  transform: scale(0.8);
}

.share_gift5 {
  left: 272px;
  bottom: -20px;
}

/* 底部联系 */
.contact {
  padding: 35px 0 0;
}

.contact_gift1 {
  left: 61px;
  top: 19px;
}

.phone {
  width: 139px;
  height: 26px;
  margin: 0px auto 0;
}

.phone > img {
  width: 100%;
  height: 100%;
}

.contact_bottom {
  position: relative;
  vertical-align: bottom;
  left: 0;
  bottom: 0;
  width: 375px;
  height: 105px;
}

/* 遮罩 */
.mask {
  width: 375px;
  height: 100%;
  background-color: transparent;
  position: fixed;
  z-index: 999;
}

/* 分享成功 */
.share_success,
.receive_success {
  width: 285px;
  height: 400px;
  margin: 0 auto;
  position: relative;
  top: 20%;
  background-color: #fff;
  border-radius: 8px;
}
.share_success {
  top: 0;
  left: -100%;
  margin: 0;
  /* opacity: 0; */
}

.share_success {
  height: 450px;
}

.share_success_top_box {
  width: 285px;
  height: 450px;
  top: 0;
  left: 0;
}

.share_success_top {
  width: 285px;
  height: 156px;
}

.canvas_loading {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  margin-left: -15px;
  margin-top: -15px;
  color: #fff;
}

.avatar_box {
  position: relative;
  top: 135px;
  left: 108px;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  background-color: #fff;
}

.share_success_avatar {
  /* width: 67px;
  height: 67px; */
  width: 50px;
  /* height: 50px; */
  position: absolute;
  left: 12px;
  top: 12px;
  border-radius: 50%;
  /* border: 3px solid #000; */
}

/* 其他的东西 */
.other {
  margin: 140px auto 0;
  color: #333;
  font-family: PingFang-SC-Medium;
}

/* 分享成功的姓名 */
.name {
  margin: 0 30px;
  font-size: 13px;
}

.status {
  margin: 8px auto 0;
  font-size: 18px;
}

.ercode {
  margin-top: 10px;
  width: 59px;
  height: 59px;
  font-size: 0;
  line-height: 6px;
  vertical-align: bottom;
}

.youhuijia {
  font-family: PingFang-SC-Medium;
  font-size: 10px;
}

/* 分享成功的底部样式 */
.share_success_bottom {
  position: absolute;
  bottom: 13px;
  font-size: 10px;
  color: #666;
  width: 100%;
  z-index: 99;
}

/* 领取成功 */
.receive_success {
  width: 295px;
  height: 312px;
  background: #fff url(../assets/receive/bg.png) no-repeat fixed center;
  background-size: 100% 100%;
  background-position: 0 -50px;
}

.receive_success_top {
  width: 321px;
  height: 184px;
  position: absolute;
  top: -50px;
  left: -30px;
}

.receive_success_close {
  width: 25px;
  height: 25px;
  position: absolute;
  top: -35px;
  right: -12px;
}

/* 领取弹窗的输入框 */
.receive_success {
  height: 176px;
  padding-top: 145px;
}

.receive_success_input {
}

.receive_success_input input {
  margin: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  border: 1px solid #f97721;
  padding: 0 8px;
  font-size: 9px;
  border-radius: 5px;
  box-sizing: border-box;
}

.receive_success_input input::-webkit-input-placeholder {
  color: #ccc;
}

.receive_success_input > div {
  width: 214px;
  height: 27px;
  margin: 10px auto 0;
}

.receive_success_input1 {
  width: 214px;
  height: 100%;
  float: left;
}

/* 输入验证码这一行 */
.receive_success_input2 {
  width: 148px;
  height: 27px;
  float: left;
}

.receive_success_line2_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  width: 62px;
  height: 27px;
  /* line-height: 27px; */
  border-radius: 5px;
  font-size: 9px;
  color: #fff;
  background-color: #f97721;
}

.receive_success_line2_btn.active {
  background-color: #4c4c4c;
  color: #fff;
}

.receive_success_line2_btn > div {
  vertical-align: bottom;
  transform: scale(0.8);
  transform-origin: center center;
}

/* 领取按钮 和 点击领取 之后领取成功 */
.receive_success_tip,
.receive_success_btn {
  width: 177px;
  height: 39px;
  line-height: 38px;
  font-size: 17px;
  background-image: url(../assets/receive/btn.png);
  background-size: 100% 100%;
  color: #fff3cc;
  margin: 40px auto;
}

.receive_success_btn {
  margin-top: 15px;
}

.receive_success_tip {
  margin-bottom: 15px;
}

.receive_success_nav {
  font-size: 10px;
  color: #000;
  font-family: "PingFang-SC-Regular";
}

.receive_success_nav > span {
  color: #fd822a;
  margin-left: 1px;
  text-decoration: underline;
}

/* 弹窗出现消失动画 */
.slide-enter-active {
  animation-name: slideInUp;
  animation-duration: 0.2s;
  animation-fill-mode: both;
}

.slide-leave-active {
  animation-name: slideOutDown;
  animation-duration: 0.2s;
  animation-fill-mode: both;
}

@keyframes slideInUp {
  0% {
    transform: scale(0);
    visibility: visible;
  }

  to {
    transform: scale(1);
  }
}

@keyframes slideOutDown {
  0% {
    transform: scale(1);
  }

  to {
    visibility: hidden;
    transform: scale(0);
  }
}

/* 时针旋转动画 */
.hand {
  position: absolute;
  top: 58px;
  left: 277px;
  width: 32px;
  height: 27px;
  animation: abc 3s linear infinite;
  transform-origin: 5px 24px;
  border-radius: 150px 150px 0 0;
}

@keyframes abc {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animate_pause {
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
}

/* 引导分享页 */
.lead {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  color: #fff;
}

/* 分享的二维码 */
.share_code {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
}
#qrcode {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  margin-top: 10px;
}

.share_lead {
  width: 180px;
  position: absolute;
  right: 10px;
  top: 10px;
}

#ccc {
  position: fixed;
  top: 0;
  left: 0;
}

.savesave {
  margin-bottom: 20px;
}

.canvas_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.canvas_box > img {
  width: 285px;
  /* height: 400px; */
}
</style>

<script>
/* eslint-disable */
import "../../node_modules/animate.css/animate.min.css";
import { WOW } from "wowjs";
import { ShareSheet, Dialog } from "vant";

// 绘图
import html2canvas from "html2canvas";

// 微信分享
import wx from "weixin-js-sdk";

// 文本复制
import Clipboard from "clipboard";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      showShareSuccess: false,
      showReceive: false,
      showNav: false,
      showLead: false,

      showLoading: false,

      // 验证码的类名
      receive_success_line2_btn: {
        receive_success_line2_btn: true,
        active: false,
      },
      // 验证码倒计时控制
      codeCount: 60,
      code: 0,
      // 控制是否点击了立即领取
      receiveSuccess: false,

      // 分享
      showShare: false,
      shareOptions: [
        {
          name: "微信",
          icon: "wechat",
        },
        {
          name: "朋友圈",
          icon: "wechat-moments",
        },
        {
          name: "复制链接",
          icon: "link",
        },
      ],

      // 手机验证码之类
      phoneNum: "",
      // 手机号正则
      phoneReg:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      // 优惠券id
      couponId: "",

      // 当前浏览器环境
      webEnv: "",

      // 请求基础路径
      baseURL: this.$axios.defaults.baseURL,

      // 页面的图片
      imgs: null,

      // 显示分享二维码
      showCode: false,

      ShareImg: "",
    };
  },

  components: {
    ShareSheet,
    Dialog,
  },

  watch: {
    showShareSuccess(oldVal) {
      this.judgeScroll(oldVal);
    },
    showLead(oldVal) {
      this.judgeScroll(oldVal);
    },
    showReceive(oldVal) {
      let doc = this.$refs.quan;
      if (oldVal) {
        doc.classList.add("animate_pause");
      } else {
        doc.classList.remove("animate_pause");
      }
      this.judgeScroll(oldVal);
    },
  },

  methods: {
    // 更新微信自定义分享
    updateShare () {
      // 获取分享地址
      this.$axios({
        url: "/api/v1/share/mp",
        method: "get",
        params: {
          mobile: sessionStorage.getItem('m') || '',
          shareUrl: location.href,
        },
      }).then((res) => {
        // console.log(res, "获取分享地址成功");
        this.shareUrl = res.data.data;
        let ccc = res.data.data.link
        // console.log(this.shareUrl, "获取分享地址成功1");
        // alert('alert:' + location.href.split('#')[0])
        this.$axios({
          url: "/api/v1/share/getShareData",
          method: "get",
          params: {
            url: this.shareUrl.link.split("#")[0],
            // url: location.href.split("#")[0],
            // url: res.data.data || ""
          },
        }).then((res) => {
          // console.log(res, "获取微信分享配置成功");
          // alert(res.data.data.url)
          let data = res.data.data;
          this.linkUrl = data.url;
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.appId, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
          });
          wx.ready(() => {
            let dataConfig = {
              title: this.shareUrl.title, // 分享标题
              desc: this.shareUrl.desc, // 分享描述
              link: ccc, // 分享链接，该链接域名必须与当前企业的可信域名一致
              // link: this.shareUrl.link, // 分享链接，该链接域名必须与当前企业的可信域名一致
              imgUrl: this.shareUrl.imgUrl, // 分享图标
              success: function () {
                // alert("设置成功");
                // 用户确认分享后执行的回调函数
              },
              cancel: function () {
                alert("设置失败");
                // 用户取消分享后执行的回调函数
              },
            };
            wx.updateAppMessageShareData(dataConfig);
            wx.updateTimelineShareData(dataConfig);
          });
        });
      });
    },
    // 点击分享
    share() {
      // this.showShareSuccess = true;
      // 获取本地缓存的手机号 如果没有手机号 提醒用户去领取
      let mobile = sessionStorage.getItem("m");
      if (!mobile) {
        Dialog.alert({
          title: "请领取优惠券领取后再进行分享",
        }).then(() => {
          this.showReceive = true;
        });
      } else {
        // 如果没有配置过 发送请求配置
        if (!this.shareUrl && !this.linkUrl) {
          // 获取分享地址
          this.$axios({
            url: "/api/v1/share/mp",
            method: "get",
            params: {
              mobile: mobile,
            },
          }).then((res) => {
            // console.log(res, "获取分享地址成功");
            this.shareUrl = res.data.data;
            // alert('alert:' + location.href.split('#')[0])
            this.$axios({
              url: "/api/v1/share/getShareData",
              method: "get",
              params: {
                url: location.href.split("#")[0],
                // url: res.data.data || ""
              },
            }).then((res) => {
              // console.log(res, "获取微信分享配置成功");
              // alert(res.data.data.url)
              let data = res.data.data;
              this.linkUrl = data.url;
              wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: data.appId, // 必填，公众号的唯一标识
                timestamp: data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.signature, // 必填，签名
                jsApiList: [
                  "updateAppMessageShareData",
                  "updateTimelineShareData",
                ],
              });
              CallBack.call(this);
            });
          });
        } else {
          CallBack.call(this);
        }
        function CallBack() {
          let that = this;
          this.showShareSuccess = true;
          this.showLoading = true;
          setTimeout(() => {
            this.qr = new QRCode("qrcode", {
              width: 100,
              height: 100, // 高度
              text: this.shareUrl.link, // 二维码内容
              // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
              // background: '#f0f'
              // foreground: '#ff0'
            });
            setTimeout(() => {
              // console.log(this.$refs.abcdefg)
              // return
              let ele = document.getElementsByClassName("share_success")[0];
              html2canvas(ele, {
                backgroundColor: null,
                y: window.pageYOffset,
              }).then(function (canvas) {
                // console.log(canvas)
                canvas.id = "ccc";
                canvas.style.position = "fixed";
                canvas.style.bottom = "0";
                canvas.style.left = "0";
                canvas.style.zIndex = "999";
                // 转成图片，生成图片地址
                let aaa = canvas.toDataURL("image/png");
                // console.log(aaa)
                that.ShareImg = aaa;
                that.showLoading = false;
                // document.body.appendChild(canvas);
              });
            }, 1000);
          }, 100);
          return;
          this.showShare = true;
          // 给第三个复制链接添加点击事件
          this.$nextTick(() => {
            console.log(
              document.getElementsByClassName("van-share-sheet__option")[2]
            );
            document
              .getElementsByClassName("van-share-sheet__option")[2]
              .setAttribute("data-clipboard-text", this.shareUrl.link);
            var clipboard = new Clipboard(
              ".van-share-sheet__option:nth-child(3)"
            );
            clipboard.on("success", function (e) {
              Dialog.alert({
                title: "复制成功",
              });
            });

            clipboard.on("error", function (e) {
              Dialog.alert({
                title: "复制失败,请手动复制",
              });
            });
          });
        }
      }
    },

    copyText(msg) {
      var input = document.getElementById("input");
      input.value = msg; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行浏览器复制命令
      alert("复制成功");
    },

    // 分享弹窗中的某个点击
    onSelect(option) {
      if (option.name == "微信" || option.name == "朋友圈") {
        // 如果是微信内置浏览器，弹引导分享
        if (
          window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
          "micromessenger"
        ) {
          // alert(this.baseURL + this.shareUrl.imgUrl)
          // 配置自定义分享
          console.log(this.shareUrl);
          let data = {
            title: this.shareUrl.title, // 分享标题
            desc: this.shareUrl.desc, // 分享描述
            link: this.shareUrl.link, // 分享链接，该链接域名必须与当前企业的可信域名一致
            imgUrl: this.baseURL + this.shareUrl.imgUrl, // 分享图标
            success: function () {
              // alert("设置成功");
              // 用户确认分享后执行的回调函数
            },
            cancel: function () {
              alert("设置失败");
              // 用户取消分享后执行的回调函数
            },
          };
          wx.updateAppMessageShareData(data);
          wx.updateTimelineShareData(data);

          // 关闭底部弹窗
          this.showShare = false;

          // 显示引导蒙版
          this.showLead = true;
        }
        // 否则显示二维码
        else {
          // 关掉下面的弹窗
          this.showShare = false;
          // console.log(this.shareUrl.link)

          this.showShareSuccess = true;
          setTimeout(() => {
            this.qr = new QRCode("qrcode", {
              width: 100,
              height: 100, // 高度
              text: this.shareUrl.link, // 二维码内容
              // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
              // background: '#f0f'
              // foreground: '#ff0'
            });
          }, 100);
        }
        // this.showShareSuccess = true;
      } else if (option.name == "复制链接") {
      }
    },

    recive() {
      this.showReceive = true;
    },

    // 点击关闭弹窗
    close(e) {
      if (e.target.dataset.close) {
        this.closeFun();
      }
    },
    closeFun() {
      this.showShareSuccess = false;
      this.showReceive = false;
      this.showNav = false;
      this.showLead = false;
      this.showCode = false;
      this.ShareImg = "";
      this.showLoading = false;
    },
    // 点击获取验证码时修改按钮的类名
    gainCode() {
      // 判断手机号是否正确
      if (!this.phoneReg.test(this.phoneNum)) {
        Dialog.alert({
          title: "手机号格式不正确",
        });
      } else {
        this.receive_success_line2_btn.active = true;
        this.code = this.codeCount;
        let gainCodeInterval = setInterval(() => {
          this.code--;
          if (this.code <= 0) {
            clearInterval(gainCodeInterval);
            this.receive_success_line2_btn.active =
              !this.receive_success_line2_btn.active;
          }
        }, 1000);
        this.$axios({
          url: "/api/v1/sms/mp/send",
          method: "get",
          params: {
            mobile: this.phoneNum,
          },
        }).then((res) => {
          if (res.data.status == 200) {
          }
          // 如果该手机号已注册
          else if (res.data.status == 300102) {
            sessionStorage.setItem("m", this.phoneNum);
            Dialog.alert({
              title: res.data.message,
            }).then(() => {
              this.closeFun();
              this.share();
            });
          } else if (res.data.status) {
            Dialog.alert({
              title: res.data.message,
            });
          }
        });
      }
    },

    // 点击领取
    receiveNow() {
      // console.log(this.pwdNum)
      // console.log(this.codeNum)
      // console.log(this.phoneNum)

      // 手机号正则

      // 验证三者不能为空
      if (!this.phoneNum) {
        Dialog.alert({
          title: "手机号不能为空",
        });
      } else if (!this.codeNum) {
        Dialog.alert({
          title: "验证码不能为空",
        });
      }
      // else if (!this.pwdNum) {
      //   Dialog.alert({
      //     title: "密码不能为空",
      //   });
      // }
      // 验证手机号格式
      else if (!this.phoneReg.test(this.phoneNum)) {
        Dialog.alert({
          title: "手机号格式不正确",
        });
      } else {
        // 验证密码格式
        // 验证手机号与验证码是否正确
        this.$axios({
          url: "/api/v1/sms/mp/check",
          method: "get",
          params: {
            mobile: this.phoneNum,
            code: this.codeNum,
          },
        }).then((res) => {
          // console.log(res, "验证成功");
          // 验证成功，领取优惠券
          if (res.data.status == 200) {
            this.$axios({
              url: "/api/v1/signUp/mp",
              method: "post",
              data: {
                couponId: this.couponId,
                mobile: this.phoneNum,
                // password: this.pwdNum,
              },
            }).then((res) => {
              // console.log(res, "领取接口调用完成");
              // 领取成功
              if (res.data.status == 200) {
                // 将手机号存入本地缓存
                sessionStorage.setItem("m", this.phoneNum);
                Dialog.alert({
                  title: res.data.message,
                });
                this.updateShare()
                setTimeout(() => {
                  // this.showReceive = false;
                  this.navNow();
                }, 3000);
              } else if (res.data.status == 300404) {
                sessionStorage.setItem("m", this.phoneNum);
                this.updateShare()
                Dialog.alert({
                  title: res.data.message,
                });
              }
              // 领取失败
              else {
                Dialog.alert({
                  title: res.data.message,
                });
              }
            });
          }
          // 验证失败,给用户提醒
          else {
            Dialog.alert({
              title: res.data.message,
            });
          }
        });
      }
    },

    // 立即跳转
    navNow() {
      this.closeFun();
    },

    // 判断需要开启或者关闭页面滚动
    judgeScroll() {
      // if (oldVal) {
      // 	this.stopMove()
      // } else {
      // 	this.Move()
      // }
    },
    // 禁止和开启页面滚动
    stopMove() {
      let m = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", m, {
        passive: false,
      });
    },
    Move() {
      let m = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "";
      document.removeEventListener("touchmove", m, {
        passive: true,
      });
    },
    ClosLead() {
      // console.log(1);
      this.showLead = false;
    },

    // 监听输入
    phoneInput(e) {
      this.phoneNum = e.target.value;
    },
    codeInput(e) {
      this.codeNum = e.target.value;
    },
    // pwdInput(e) {
    //   this.pwdNum = e.target.value;
    // },
    // 打电话
    ppp() {
      window.location.href = "tel:400-070-1551";
    },
  },

  beforeCreate() {
    this.$axios({
      url: "/api/v1/share/",
      method: "get",
    }).then((res) => {
      // console.log(res, "请求分享页图片");
      if (res.data.status == 200) {
        this.imgs = res.data.data;
      } else {
      }
    });
  },

  mounted() {
    // 找到图片图标的dom元素
    let giftDoc = document.querySelectorAll(".gift");
    giftDoc.forEach((item) => {
      item.classList.add("wow");
      item.classList.add("tada");
      item.setAttribute("data-wow-iteration", "infinite");
      item.setAttribute("data-wow-duration", "3s");
    });

    // 找到特点文字的dom元素
    let wordDoc = document.querySelectorAll(".word");
    wordDoc.forEach((item) => {
      item.classList.add("wow");
      item.classList.add("bounceInRight");
    });

    // 发送请求 请求优惠券价格
    this.$axios({
      url: "/api/v1/coupon/mp/get",
      method: "get",
    }).then((res) => {
      if (res.data.status == 200) {
        this.couponId = res.data.data.id;
      } else {
      }
    });

    this.updateShare()

    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        offset: 0,
        live: false,
      });
      wow.init();

      // 判断当前浏览器类型
      var browser = {
        versions: (function () {
          var u = navigator.userAgent,
            app = navigator.appVersion;
          return {
            //移动终端浏览器版本信息
            trident: u.indexOf("Trident") > -1, //IE内核
            presto: u.indexOf("Presto") > -1, //opera内核
            webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
            gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
            iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf("iPad") > -1, //是否iPad
            webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
          };
        })(),
        language: (
          navigator.browserLanguage || navigator.language
        ).toLowerCase(),
      };
      if (browser.versions.mobile) {
        //判断是否是移动设备打开。browser代码在下面
        var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
        // 如果是在微信中打开
        if (
          ua.match(/MicroMessenger/i) == "micromessenger" &&
          ua.match(/QQ/i) == "qq"
        ) {
          // alert("微信中");
          this.webEnv = "weChat";
        } else if (ua.match(/QQ/i) == "qq") {
          // alert("QQ中");
          this.webEnv = "QQ";
        } else {
          // alert("pc端");
          this.webEnv = "PC";
        }
        // if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //   //在微信中打开
        //   alert('微信中打开')
        // }
        // if (ua.match(/WeiBo/i) == "weibo") {
        //   //在新浪微博客户端打开
        //   alert('微博中打开')
        // }
        // if (ua.match(/QQ/i) == "qq") {
        //   //在QQ空间打开
        //   alert('qq中打开')
        // }
        // if (browser.versions.ios) {
        //   //是否在IOS浏览器打开
        // }
      } else {
        //否则就是PC浏览器打开
        // alert("PC中打开");
        this.webEnv = "PC";
      }

      // 用的两个不用怕的图片,控制显示隐藏
      setTimeout(() => {
        let doc1 = this.$refs.noFearWord1;
        let doc2 = this.$refs.noFearWord2;
        doc1.classList.add("d-no");
        doc2.classList.remove("d-no");
      }, 4500);
    });
  },
};
</script>
