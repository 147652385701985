import Vue from 'vue/dist/vue.min.js'
import App from './App.vue'

// 移动端适配
// import 'amfe-flexible/index.js'
// import 'lib-flexible'
import '../rem.js'
// import '../.postcssrc.js'
// import '../vue.config.js'

// vue-router
import router from './routes/index.js'

import { Loading } from 'vant';

Vue.use(Loading);

// 动画包
import animated from 'animate.css'
import 'animate.css'
Vue.use(animated)

// axios
import axios from './axios.js'
Vue.prototype.$axios = axios


Vue.config.productionTip = false
new Vue({
	router,
  render: h => h(App),
}).$mount('#app')
