<template>
	<div>
		错误路径，即将自动跳转
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		beforeMount() {
			setTimeout(() => {
				this.$router.push({
					name: 'index'
				})
				}, 2000)
		}
	}
</script>

<style>
</style>
