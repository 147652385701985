import Vue from 'vue/dist/vue.min.js'
//引入router
import Router from 'vue-router'

import Index from '../view/index.vue'
import notFound from '../view/notFound.vue'

Vue.use(Router)

export default new Router({
	mode: "hash",
	routes: [{
			path: '/',
			name: 'index',
			component: Index
		},
		{
			path: '*',
			name: 'NotFound',
			component: notFound
		}
	]
})
